import React from 'react'
import Todoapp from './Components/Todoapp'

const App = () => {
  return (
    <div>
      <Todoapp />
    </div>
  )
}

export default App
